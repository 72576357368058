import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';

import Breadcrumb from '../common/breadcrumb';
import api from '../../config/axios';
import Loader from '../common/loader';
import { date, time } from '../../utils/date';

export const renderTextPedido = (purchase) => {
    let text = '';
    if (purchase.quantity > 0) {
        text = `${purchase.quantity} recarga${purchase.quantity > 1 ? 's' : ''}`;
        if (
            purchase.quantity_new_bottles +
                purchase.quantity_starterkit +
                purchase.quantity_dispenser >
            0
        ) {
            text = text + ' y otros...';
        }
        return text;
    } else {
        if (purchase.quantity_starterkit > 0) {
            text = `${purchase.quantity_starterkit} kit de inicio`;
            if (purchase.quantity_new_bottles + purchase.quantity_dispenser > 0) {
                text = text + ' y otros...';
            }
            return text;
        }

        if (purchase.quantity_new_bottles > 0) {
            text = `${purchase.quantity_new_bottles} botellon nuevo`;
            if (purchase.quantity_dispenser > 0) {
                text = text + ' y otros...';
            }
            return text;
        }

        if (purchase.quantity_dispenser > 0) {
            text = `${purchase.quantity_dispenser} dispensador${
                purchase.quantity_dispenser > 1 ? 'es' : ''
            }`;
            return text;
        }
        return text;
    }
};

const ClientCoupons = withRouter((props) => {
    const { register } = useForm();
    const clientId = props.match.params.id;

    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState([]);

    useEffect(() => {
        register({ name: 'location' }, { required: true });
        Promise.all([fetchClientInfo()]).then((values) => {
            setLoaded(true);
        });
    }, []);

    const fetchSettings = async () => {
        const { data } = await api.get(`/settings`);
        const settings = data.data[0];

        return settings;
    };

    const fetchClientInfo = async () => {
        const clientRequest = api.get(`/client/${clientId}`);
        const couponsRequest = api.get(`/client/${clientId}/coupons`);
        const purchasesWithCouponRequest = api.get(`/client/${clientId}/purchases-with-coupon`);

        const values = await Promise.all([
            clientRequest,
            couponsRequest,
            purchasesWithCouponRequest,
        ]);

        const client = values[0];
        const coupons = values[1];
        const purchases = values[2];

        setClient({
            ...client.data.data,
            coupons: coupons.data.data,
            purchases: purchases.data.data,
        });
        return;
    };

    const renderClientCoupons = (array) => {
        return array.map((item, index) => {
            return (
                <div key={`purchase-client-${index}`}>
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <p
                                style={{
                                    color: item.timesUsed === item.useLimit ? 'gray' : 'green',
                                }}
                            >
                                <strong>
                                    {item.id} - {item.code}
                                </strong>
                            </p>
                            <p style={{ margin: 0, color: 'gray' }}>{item.description}</p>
                            <p style={{ margin: 0 }}>{item.amount_usd}</p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <p className="mt-0 mb-0">{date(item.createdAt)}</p>
                        </div>
                    </div>
                    <hr />
                </div>
            );
        });
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const notUsedCoupons = client.coupons.filter((c) => c.timesUsed < c.useLimit);
    const purchasesWithCoupons = client.purchases;

    return (
        <Fragment>
            <Breadcrumb title="Cupones generador por el cliente" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="needs-validation user-add">
                                    <h4>Pendiente de usar</h4>
                                    <div>
                                        {notUsedCoupons.length > 0
                                            ? renderClientCoupons(notUsedCoupons)
                                            : 'Este cliente no ha canjeado ningún cupón'}
                                    </div>
                                    <br />
                                    <br />
                                    <h4>Cupones usados</h4>
                                    <div>
                                        {purchasesWithCoupons.length > 0
                                            ? purchasesWithCoupons.map((item, index) => {
                                                  return (
                                                      <div key={`purchase-client-${index}`}>
                                                          <div className="d-flex justify-content-between mb-3">
                                                              <div>
                                                                  <p
                                                                      style={{
                                                                          color:
                                                                              item.timesUsed ===
                                                                              item.useLimit
                                                                                  ? 'gray'
                                                                                  : 'green',
                                                                      }}
                                                                  >
                                                                      <strong>
                                                                          {item.coupon.id} -{' '}
                                                                          {item.coupon.code}
                                                                      </strong>
                                                                  </p>
                                                                  <p style={{ margin: 0 }}>
                                                                      Orden:{' '}
                                                                      <Link
                                                                          to={`${process.env.PUBLIC_URL}/recargas/detalle/${item.id}`}
                                                                      >
                                                                          {item.id}
                                                                      </Link>
                                                                  </p>
                                                                  <p
                                                                      style={{
                                                                          margin: 0,
                                                                          color: 'gray',
                                                                      }}
                                                                  >
                                                                      {item.coupon.description}
                                                                  </p>
                                                              </div>
                                                              <div style={{ textAlign: 'right' }}>
                                                                  <p className="mt-0 mb-0">
                                                                      {date(item.createdAt)}
                                                                  </p>
                                                              </div>
                                                          </div>
                                                          <hr />
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default ClientCoupons;

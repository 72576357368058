import React from 'react';
import { emojiRequested } from '../utils';
import miscUtils from '../../../utils/miscUtils';

const { currency } = miscUtils;

function OrderItem({ data }) {
    return (
        <div
            className="wrapper-order-item"
            style={{
                backgroundColor: data.preNote ? 'rgba(255,230,5,0.3)' : 'inherit',
            }}
        >
            <div className="wrapper-top-details">
                <p className="status-order mt-0 mb-2 d-flex align-items-center">
                    <span>{emojiRequested[data.requestedFrom]}</span>&nbsp;
                    {data.estado}&nbsp;{data.carreraLarga}
                </p>
                <h4 className="time-order" style={{ color: data.delivery_time ? 'red' : '' }}>
                    <i className="fa fa-clock-o"></i> {data.entrega}
                </h4>
            </div>
            <div className="wrapper-quantity-order">
                <h4 className="quantity-order">{data.cantidad}</h4>
                <span>
                    {!data.subscription ? data.paymentMethod.split('(')[0] : 'N/A'}
                    {data.couponId && <strong>(cupón)</strong>}
                    {data.payment_confirmation_id && (
                        <strong>({data.payment_confirmation_id})</strong>
                    )}
                    <strong>{data.vueltos ? `(${currency(data.vueltos).trim()})` : ''}</strong>
                </span>
            </div>
            <h4
                style={{
                    color: data.observation ? 'red' : '',
                    fontWeight: data.observation ? 'bold' : '',
                }}
                className="client-name-order"
            >
                {data.cliente}
            </h4>
            <p className="m-0">{data.direccion}</p>
            <div className="wrapper-bottom-description mt-1">
                <p style={{ maxWidth: '70vw', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data.quantityDescription}{' '}
                    {data.observationBotellon && `(${data.observationBotellon})`}
                </p>

                {data.user ? (
                    <p className="text-right">
                        <i className="fa fa-motorcycle"></i>&nbsp;
                        <strong>{data.user.name.split(' ')[0]}</strong>
                    </p>
                ) : (
                    <p className="text-right">
                        <i className="fa fa-motorcycle"></i>&nbsp;
                        <strong>Por asignar</strong>
                    </p>
                )}
            </div>
        </div>
    );
}

export default OrderItem;

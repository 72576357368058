export default {
    formatDate: 'DD/MM/YYYY',
    formatFullDate: 'DD/MM/YYYY HH:mm a',
    cleanString(value) {
        let str = this.replaceLatinChar(value);
        let re = /[^a-z0-9]+/gi;
        let re2 = /^-*|-*$/g;
        str = str.replace(re, '-');
        return str.replace(re2, '').toLowerCase();
    },
    replaceLatinChar(str) {
        return str.replace(/á|é|í|ó|ú|ñ|ä|ë|ï|ö|ü/gi, (str) => {
            str =
                str === 'á'
                    ? 'a'
                    : str === 'é'
                    ? 'e'
                    : str === 'í'
                    ? 'i'
                    : str === 'ó'
                    ? 'o'
                    : str === 'ú'
                    ? 'u'
                    : str === 'ñ'
                    ? 'n'
                    : str;
            str =
                str === 'Á'
                    ? 'A'
                    : str === 'É'
                    ? 'E'
                    : str === 'Í'
                    ? 'I'
                    : str === 'Ó'
                    ? 'O'
                    : str === 'Ú'
                    ? 'U'
                    : str === 'Ñ'
                    ? 'N'
                    : str;
            str =
                str === 'Á'
                    ? 'A'
                    : str === 'É'
                    ? 'E'
                    : str === 'Í'
                    ? 'I'
                    : str === 'Ó'
                    ? 'O'
                    : str === 'Ú'
                    ? 'U'
                    : str === 'Ñ'
                    ? 'N'
                    : str;
            str =
                str === 'ä'
                    ? 'a'
                    : str === 'ë'
                    ? 'e'
                    : str === 'ï'
                    ? 'i'
                    : str === 'ö'
                    ? 'o'
                    : str === 'ü'
                    ? 'u'
                    : str;
            str =
                str === 'Ä'
                    ? 'A'
                    : str === 'Ë'
                    ? 'E'
                    : str === 'Ï'
                    ? 'I'
                    : str === 'Ö'
                    ? 'O'
                    : str === 'Ü'
                    ? 'U'
                    : str;
            return str;
        });
    },
    cleanImageName(str) {
        return str.replace(/[^\w]/gim, '').toLowerCase();
    },
    currency(val) {
        const formatter = new Intl.NumberFormat('es-VE', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
        return formatter.format(val).replace(/USD/, '');
    },
    renderIconCard(name) {
        switch (name) {
            case 'Visa':
                return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Old_Visa_Logo.svg/524px-Old_Visa_Logo.svg.png';

            case 'MasterCard':
                return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/650px-Mastercard_2019_logo.svg.png';

            case 'American Express':
                return 'https://pngimage.net/wp-content/uploads/2018/05/amex-logo-png-2.png';

            case 'Diners Club':
                return 'https://cdn.iconscout.com/icon/free/png-256/diners-club-international-675719.png';

            default:
                break;
        }
    },
    makePasswordRandom() {
        const length = 7;
        var result = '';
        var characters = 'abcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        var numbers = '0123456789';
        var numbersLength = numbers.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result += numbers.charAt(Math.floor(Math.random() * numbersLength));
        return result;
    },
    renderTextPedido(purchase) {
        let text = '';
        let isCafeProduct = false;
        if (purchase.quantity > 0) {
            text = `${purchase.quantity} recarga${purchase.quantity > 1 ? 's' : ''}`;
            if (
                purchase.quantity_new_bottles +
                    purchase.quantity_starterkit +
                    purchase.quantity_dispenser >
                0
            ) {
                text = text + ' y otros';
            }

            if (purchase.products) {
                Object.keys(purchase.products).forEach((e) => {
                    if (purchase.products[e].name.search(/café/) > 0) {
                        isCafeProduct = true;
                    }
                });

                if (isCafeProduct) {
                    text = text + ', café y azúcar';
                }
            }
            return text;
        } else {
            if (purchase.quantity_starterkit > 0) {
                text = `${purchase.quantity_starterkit} kit de inicio`;
                if (purchase.quantity_new_bottles + purchase.quantity_dispenser > 0) {
                    text = text + ' y otros';
                }
                return text;
            }

            if (purchase.quantity_new_bottles > 0) {
                text = `${purchase.quantity_new_bottles} botellon nuevo`;
                if (purchase.quantity_dispenser > 0) {
                    text = text + ' y otros';
                }
                return text;
            }

            if (purchase.quantity_dispenser > 0) {
                text = `${purchase.quantity_dispenser} dispensador${
                    purchase.quantity_dispenser > 1 ? 'es' : ''
                }`;
                return text;
            }
            return text;
        }
    },
};
